<template>
  <div class="lsh-about" id="about">
    <div class="about-title">
      <p class="lash-title">
        关于我们
      </p>
    </div>
    <div class="about-main clearfix">
      <div class="about-left">
        <p class="about-font-left">
          蓝珊瑚工业自动化有限公司是一家专注于智能装备研发与制造，自动化方案集成与技术支持的高新技术企业；
          公司成立于2021年，位于经济发展最活跃、开放程度最高、创新能力最强的长三角经济带城市之一苏州市。
        </p>
        <div class="image-left">
          <img v-lazy="images.url1" alt="" style="width:100%;height:380px;">
        </div>
      </div>
      <div class="about-right">
        <div class="image-right">
          <img v-lazy="images.url2" alt="" style="width:100%;height:350px">
        </div>
        <p class="about-font-right">
          公司目前拥有高级自动化工程师15名，高级电气工程师3名，机器人视觉工程师2名，软件工程师2名，项目管理2名，
          核心成员皆具备超大型公司工作、管理经验及十年行业经验；
          服务行业为3C（计算机、通讯、消费电子产品）、汽车、新能源、半导体、医疗等；
          经营范围为工业自动化技术服务与支持、智能装备研发与制造、机器人视觉与软件开发、数字化工厂导入等。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      route:{
        type:String
      }
    },
    data(){
      return {
        images: {
          url1: require('@/assets/img/about1.jpg'),
          url2: require('@/assets/img/about2.jpg')
        }
      }
    },
    mounted(){

    }
  }
</script>

<style lang="less">
  .lsh-about{
    .about-title{
      position: relative;
      margin: 0 auto;
      .lash-title{
        font-size: 48px;
        text-align: center;
        color: #333;
        line-height: 48px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
    .about-main{
      background-color: #f0f0f0;
      padding:40px 85px;
      .about-left{
        float: left;
        width: 50%;
        .about-font-left{
          font-size: 16px;
          text-align: left;
          font-weight: 700;
          padding: 18px 20px;
        }
        .image-left{
          padding: 20px;
        }
      }
      .about-right{
        float:right;
        width: 50%;
        .about-font-right{
          font-size: 16px;
          text-align: left;
          font-weight: 700;
          padding: 0 20px;
        }
        .image-right{
          padding: 20px;
        }
      }
    }
    .clearfix{
      *zoom: 1;
    }
    .clearfix:after{
      content: "";
      display: block;
      height: 0;
      clear:both;
      visibility: hidden;
    }
  }
</style>
